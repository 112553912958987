import React from "react";

import { Intro } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { mapFilterToRequest, mapDataToList } from "./user-access.mapper";
import { columns } from "./user-access-column";
import { initialFilterState } from "./user-access-filter.state";
import { useApi, useModal } from "hooks";
import UserAccessFilter from "./user-access-filter";
import { getUsers, removeUser } from "apis/profile.api";
import UserAccessForm from "./user-access-form";
import { Portal, UserStatus, PLBTypes } from "enums";
import { handleRequest } from "utils";
import { ConfirmModal } from "components/modals";
import { useContext } from "react";
import { FleetContext } from "contexts";

const UserAccessModule = () => {
  const confirmModal = useModal();
  const { close } = confirmModal;

  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;

  const { request: removeUserRequest } = useApi({
    api: removeUser,
    handleOwnError: {
      badrequest: true,
    },
  });

  const handleUpdateRole = (params, email) => {
    const { role, userAccessId, fullName, isFaEnabled, currentFA } = params;
    userAccessFormModal.show({
      title: locale.editDetails,
      actionText: locale.add,
      isEdit: true,
      fullName,
      currentFA,
      initialState: {
        email: {
          value: email,
        },
        role: {
          value: role,
        },
        userAccessId: {
          value: userAccessId,
        },
        twoFactorAuth: {
          value: isFaEnabled,
        },
      },
    });
  };

  const handleRemoveUser = (params, email) => {
    confirmModal.show({
      title: locale.removedUserQuestion,
      content: (
        <div>
          <locale.Populate text={locale.youAreAboutRemoveUser} items={[<b>{email}</b>]} />
        </div>
      ),
      secondary: {
        text: locale.Cancel,
      },
      primary: {
        text: locale.yesRemoveUser,
        onClick: async () => {
          submitRemoveUser(params);
          close();
          confirmModal.show({
            image: "",
            title: locale.exclamatedSuccess,
            content: (
              <div>
                <locale.Populate text={locale.successfullyRemoveUser} items={[<b>{email}</b>]} />
              </div>
            ),
            primary: {
              text: locale.gotIt,
              onClick: () => {
                close();
              },
            },
          });
        },
      },
    });
  };

  const submitRemoveUser = async (params) => {
    handleRequest(async () => {
      await removeUserRequest({
        ...params,
      });

      table.fetch({});
    });
  };

  const { filter, search, table } = useDataTable({
    api: {
      api: getUsers,
      params: {
        portal: Portal.FLEET,
        fleetId,
        statuses: `${UserStatus.Active},${UserStatus.Deactivated}`,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "users",
      mapper: (users) =>
        mapDataToList({
          users,
          handleUpdateRole,
          handleRemoveUser,
        }),
      columns,
    },
  });

  const userAccessFormModal = useModal();

  return (
    <>
      <ConfirmModal {...confirmModal} />
      <UserAccessForm
        {...userAccessFormModal}
        refreshPage={(isEdit) => {
          table.fetch({});
          if (isEdit) {
            userAccessFormModal.close();
          }
        }}
      />
      <Intro
        title={locale.userAccess}
        subtitle={locale.monitorUserAccessToFleetPortal}
        actionText={locale.addUser}
        actionOnClick={() => {
          userAccessFormModal.show({
            title: locale.addUser,
            actionText: locale.add,
            isEdit: false,
            initialState: {
              email: {
                value: "",
              },
            },
          });
        }}
      />
      <UserAccessFilter
        {...filter}
        {...search}
        isLocqPayAccount={
          fleet.plbType === PLBTypes.PLB_LOCQPAY || fleet.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO
        }
      />
      <DataTableV2 {...table} paginationV2={true} />
    </>
  );
};

export default UserAccessModule;
